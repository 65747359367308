import React from "react";
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";
import Subheading from "../components/Subheading";
import BulletPoint from "../components/BulletPoint";

export default function PrivacyPolicy() {
  const date = "July 10, 2024";
  const app_name = "Scene";
  const company_information = "BaljeetKode";
  const country = "India";
  const company_address = "Delhi, India";
  const website_name = "Scene";
  const website_url = "https://www.sceneweb.app/";
  const website_contact_email = "contact@baljeetkode.com";
  const phone = "+91 99999-99999";
  const website_contact_page_url = "https://www.sceneweb.app/contact";
  return (
    <div className="w-full h-full bg-primary flex flex-col items-center">
      <div className="w-full h-20 sm:h-1/6 flex bg-primary-light items-center border-b-2">
        <div className="w-28 flex justify-center">
          <img src="./logo.svg" className="h-14" />
        </div>
        <div className="w-auto flex justify-center">
          <p className="font-sans font-medium text-xl text-secondary">
            Privacy Policy
          </p>
        </div>
      </div>
      <div className="w-full h-5/6 flex flex-col items-center overflow-y-scroll">
        <div className="w-full sm:w-5/6 flex flex-col bg-primary-light items-center mt-16  sm:py-12 sm:px-24 py-8 px-8 text-wrap">
          <Heading heading={"Privacy Policy"} />
          <Paragraph paragraph={`Last updated: ${date}`} />
          <Paragraph
            paragraph={`This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.`}
          />
          <Paragraph
            paragraph={`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.`}
          />
          <Heading heading={"Interpretation and Definitions"} />
          <Subheading subheading={"Interpretation"} />
          <Paragraph
            paragraph={`The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`}
          />
          <Subheading subheading={"Definitions"} />
          <Paragraph paragraph={`For the purposes of this Privacy Policy:`} />
          <BulletPoint
            highlightedText={"Account"}
            statement={`means a unique account created for You to access our Service or parts of our Service.`}
          />
          <BulletPoint
            highlightedText={"Affiliate"}
            statement={`means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`}
          />
          <BulletPoint
            highlightedText={"Application"}
            statement={`refers to ${app_name}, the software program provided by the Company.`}
          />
          <BulletPoint
            highlightedText={"Company"}
            statement={`refers to the Website.`}
          />
          <BulletPoint
            highlightedText={"Website"}
            statement={`(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ${company_information}, ${company_address}.`}
          />
          <BulletPoint
            highlightedText={"Cookies"}
            statement={`are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.`}
          />
          <BulletPoint
            highlightedText={"Country"}
            statement={`refers to: ${country}`}
          />
          <BulletPoint
            highlightedText={"Device"}
            statement={`means any device that can access the Service such as a computer, a cellphone or a digital tablet.`}
          />
          <BulletPoint
            highlightedText={"Personal Data"}
            statement={`is any information that relates to an identified or identifiable individual.`}
          />
          <BulletPoint
            highlightedText={"Service"}
            statement={`refers to the Application or the Website or both.`}
          />
          <BulletPoint
            highlightedText={"Service Provider"}
            statement={`means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.`}
          />
          <BulletPoint
            highlightedText={"Third-party Social Media Service"}
            statement={`efers to any website or any social network website through which a User can log in or create an account to use the Service.`}
          />
          <BulletPoint
            highlightedText={"Usage Data"}
            statement={`refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`}
          />
          <BulletPoint
            highlightedText={"Website"}
            statement={`refers to ${website_name}, accessible from ${website_url}`}
          />
          <BulletPoint
            highlightedText={"You"}
            statement={`means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`}
          />

          <Heading heading={"Collecting and Using Your Personal Data"} />
          <Subheading subheading={"Types of Data Collected"} />
          <Subheading subheading={"Personal Data"} />
          <Paragraph
            paragraph={`While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:`}
          />
          <BulletPoint statement={`Email address`} />
          <BulletPoint statement={`Email address`} />
          <BulletPoint statement={`Phone number`} />
          <BulletPoint
            statement={`Address, State, Province, ZIP/Postal code, City`}
          />
          <BulletPoint statement={`Usage Data`} />
          <Subheading subheading={"Usage Data"} />
          <Paragraph
            paragraph={`Usage Data is collected automatically when using the Service.`}
          />
          <Paragraph
            paragraph={`Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.`}
          />
          <Paragraph
            paragraph={`When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.`}
          />
          <Paragraph
            paragraph={`We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.`}
          />
          <Subheading
            subheading={"Information from Third-Party Social Media Services"}
          />
          <Paragraph
            paragraph={`The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:`}
          />
          <BulletPoint statement={`Google`} />
          <Paragraph
            paragraph={`If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.`}
          />
          <Paragraph
            paragraph={`You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.`}
          />
          <Subheading
            subheading={"Information Collected while Using the Application"}
          />
          <Paragraph
            paragraph={`While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:`}
          />
          <BulletPoint statement={`Information regarding your location`} />
          <BulletPoint
            statement={`Pictures and other information from your Device's camera and photo library`}
          />
          <Paragraph
            paragraph={`We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.`}
          />
          <Paragraph
            paragraph={`You can enable or disable access to this information at any time, through Your Device settings.`}
          />
          <Subheading subheading={"Tracking Technologies and Cookies"} />
          <Paragraph
            paragraph={`We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:`}
          />
          <BulletPoint
            highlightedText={"Cookies or Browser Cookies"}
            statement={` A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.`}
          />
          <BulletPoint
            highlightedText={"Web Beacons"}
            statement={`Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).`}
          />
          <Paragraph
            paragraph={`Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on TermsFeed website article.`}
          />
          <Paragraph
            paragraph={`We use both Session and Persistent Cookies for the purposes set out below:`}
          />
          <BulletPoint highlightedText={"Necessary / Essential Cookies"} />
          <Paragraph paragraph={`Type: Session Cookies`} />
          <Paragraph paragraph={`Administered by: Us`} />
          <Paragraph
            paragraph={`Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.`}
          />
          <BulletPoint
            highlightedText={"Cookies Policy / Notice Acceptance Cookies"}
          />
          <Paragraph paragraph={`Type: Persistent Cookies`} />
          <Paragraph paragraph={`Administered by: Us`} />
          <Paragraph
            paragraph={`Purpose: These Cookies identify if users have accepted the use of cookies on the Website.`}
          />
          <BulletPoint highlightedText={"Functionality Cookies"} />
          <Paragraph paragraph={`Type: Persistent Cookies`} />
          <Paragraph paragraph={`Administered by: Us`} />
          <Paragraph
            paragraph={`Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.`}
          />
          <Paragraph
            paragraph={`For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.`}
          />
          <Subheading subheading={"Use of Your Personal Data"} />
          <Paragraph
            paragraph={`The Company may use Personal Data for the following purposes:`}
          />
          <BulletPoint
            highlightedText={"To provide and maintain our Service"}
            statement={`, including to monitor the usage of our Service.`}
          />
          <BulletPoint
            highlightedText={"To manage Your Account"}
            statement={`: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.`}
          />
          <BulletPoint
            highlightedText={"For the performance of a contract"}
            statement={`: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.`}
          />
          <BulletPoint
            highlightedText={"To contact You"}
            statement={`: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.`}
          />
          <BulletPoint
            highlightedText={"To provide You"}
            statement={`with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.`}
          />
          <BulletPoint
            highlightedText={"To manage Your requests"}
            statement={`: To attend and manage Your requests to Us.`}
          />
          <BulletPoint
            highlightedText={"For business transfers"}
            statement={`: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.`}
          />
          <BulletPoint
            highlightedText={"For other purposes"}
            statement={`: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.`}
          />
          <Paragraph
            paragraph={`We may share Your personal information in the following situations:`}
          />
          <BulletPoint
            highlightedText={"With Service Providers"}
            statement={`: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.`}
          />
          <BulletPoint
            highlightedText={"For business transfers"}
            statement={`: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.`}
          />
          <BulletPoint
            highlightedText={"With Affiliates"}
            statement={`: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.`}
          />
          <BulletPoint
            highlightedText={"With business partners"}
            statement={`: We may share Your information with Our business partners to offer You certain products, services or promotions.`}
          />
          <BulletPoint
            highlightedText={"With other users"}
            statement={`: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.`}
          />
          <BulletPoint
            highlightedText={"With Your consent"}
            statement={`: We may disclose Your personal information for any other purpose with Your consent.`}
          />
          <Subheading subheading={"Retention of Your Personal Data"} />
          <Paragraph
            paragraph={`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.`}
          />
          <Paragraph
            paragraph={`The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`}
          />
          <Subheading subheading={"Transfer of Your Personal Data"} />
          <Paragraph
            paragraph={`Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.`}
          />
          <Paragraph
            paragraph={`Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.`}
          />
          <Paragraph
            paragraph={`The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.`}
          />
          <Subheading subheading={"Delete Your Personal Data"} />
          <Paragraph
            paragraph={`You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.`}
          />
          <Paragraph
            paragraph={`Our Service may give You the ability to delete certain information about You from within the Service.`}
          />
          <Paragraph
            paragraph={`You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.`}
          />
          <Paragraph
            paragraph={`Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.`}
          />
          <Subheading subheading={"Disclosure of Your Personal Data"} />
          <Subheading subheading={"Business Transactions"} />
          <Paragraph
            paragraph={`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.`}
          />
          <Subheading subheading={"Law enforcement"} />
          <Paragraph
            paragraph={`Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`}
          />
          <Subheading subheading={"Other legal requirements"} />
          <Paragraph
            paragraph={`The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:`}
          />
          <BulletPoint statement={`Comply with a legal obligation`} />
          <BulletPoint
            statement={`Protect and defend the rights or property of the Company`}
          />
          <BulletPoint
            statement={`Prevent or investigate possible wrongdoing in connection with the Service`}
          />
          <BulletPoint
            statement={`Protect the personal safety of Users of the Service or the public`}
          />
          <BulletPoint statement={`Protect against legal liability`} />
          <Subheading subheading={"Security of Your Personal Data"} />
          <Paragraph
            paragraph={`The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.`}
          />
          <Heading heading={"Children's Privacy"} />
          <Paragraph
            paragraph={`Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.`}
          />
          <Paragraph
            paragraph={`If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.`}
          />
          <Heading heading={"Links to Other Websites"} />
          <Paragraph
            paragraph={`Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.`}
          />
          <Paragraph
            paragraph={`We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`}
          />
          <Heading heading={"Changes to this Privacy Policy"} />
          <Paragraph
            paragraph={`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.`}
          />
          <Paragraph
            paragraph={`We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.`}
          />
          <Paragraph
            paragraph={`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`}
          />
          <Heading heading={"Contact Us"} />
          <Paragraph
            paragraph={`If you have any questions about this Privacy Policy, You can contact us:`}
          />
          <BulletPoint statement={`By email: ${website_contact_email}`} />
          <BulletPoint
            statement={`By visiting this page on our website: ${website_contact_page_url}`}
          />
          <BulletPoint statement={`By phone number: ${phone}`} />
          <BulletPoint statement={`By mail: ${company_address}`} />
        </div>
      </div>
    </div>
  );
}
